import { MouseEventHandler, useEffect } from 'react';
import './App.css';
import IonIcon from '@reacticons/ionicons';
import { Item } from './Item';
import beginning from './images/beginning.jpg'
import bratStefan from './images/brat-stefan.jpg'
import bratSocial from './images/brat-social.png'
import bratInTheSun from './images/brat-in-the-sun.jpg'
import braptains from './images/the-braptains.jpeg'

function App() {
  const toSpotify = () => {
    window.location.href = 'https://open.spotify.com/playlist/2Gli23WUY8ZSv5MdQDV8rB?si=169a850eebac42b8';
  }
  function handleClick(arg: 'prev' | 'next') {
    const slider = document.querySelector(".slider") as Element;
    const items = document.querySelectorAll(".item");
    console.log('slider', slider);
    console.log('items', items); if (arg === 'prev') {
      slider.append(items[0]);
    } else {
      slider.prepend(items[items.length - 1]);
    }
  }

  return (
    <main>
      <div className="slider">
        <Item image={bratInTheSun}
          title='Bråt = solsken?'
          description='Legenden säger att när bråten väl sjösätts kommer solen alltid fram. 
                            Vad vi kan säga med säkerhet är i vart fall att när solen är framme, 
                            syns även en bråt, med välfyllda corona väskor ute på Långsjöns glimrande vatten.'
        />
        {/* <!-- visibele item --> */}
        <Item image={bratInTheSun}
          title='Välkommen till Bråten'
          description='Bråten [{neut.} /²broːtɛ/n], döpt från det fornnordiska att brygga och bråta 
                            av bråte, är en skapelse utan dess like som ofta kan ses skvalpa runt 
                            på långsjön i villaområdet Älvsjö. Alltid omgivet av skratt, glädje och 
                            en uppskattning av allt livets goda, sägs Bråten vara den sanna källan till 
                            ett lyckligt liv.'
        />
        <Item image={braptains}
          title='Braptenerna'
          description='Bråtens kaptener är två män, eller pojkar, i 30-årsåldern, som
                      en vinter med alldeles för lite att göra, tog sig an den gamla ädla konsten
                      att brygga, dvs. att bygga en bråt. Inte att förväxla med att brågga, som är att 
                      med en själslig kontakt med sin bråt, brygga ett öl.'
        />
        <Item image={beginning}
          title='Historia'
          description='Det hela började med en före detta båttrailers plötsliga bortgång. Braptenerna 
          tog detta tillfälle i akt att inte låta bestens kadaver gå till spillo. Detta blev stommen till den ack så hållfasta 
          konstruktion som än idag bär Bråten längs med Långsjöns praktfulla vatten.'
        />
        <Item image={bratStefan}
          title='Säkerhet'
          description='Med dagens samhällsklimat och Bråtens växande närvaro behövde braptenerna 
                      ta till vissa säkerhetsåtgärder. Stefan, bräktaren, har sedan en tid tillbaka 
                      ansvarat för säkerheten på Bråten. "Med tidigare erfarenhet av att skydda 
                      tryckimpregnerat virke och en outtröttlig energi kan vi inte se att någon 
                      skulle vara bättre anpassad för jobbet." / Brapten'
        />
        <Item image={bratSocial}
          title='Ska du med?'
          description='Är du en av de lyckliga få som har fått äran att följa med på 
          en tur längs långsjöns glimrande vatten? Du ska då ha fått en folder med all information 
          om eventuella förnödenheter som du behöver ta med dig och ett passerkort för att komma igenom 
          säkerheten. Vill du bidra till musiken som spelas till bråten, lägg då gärna till i spellistan nedan.'
          children={<button onClick={() => toSpotify()}>Till Spotify</button>}
          /> 
      </div>
      <nav className='nav'>
        <IonIcon onClick={() => handleClick('prev')} className='btn prev' name='arrow-back-outline' />
        <IonIcon onClick={() => handleClick('prev')} className='btn next' name='arrow-forward-outline' />
      </nav>
    </main>

  );
}


export default App;
