import { FC } from "react"

type Props = {
    image: string;
    title: string;
    description: string;
    children?: React.ReactNode;
}

export const Item: FC<Props> = ({image, title, description, children}) => {
    return (
        <div className="item">
          <img src={image} loading="eager" alt="" />
          <div className='content'>
            <h2 className='title'>{title}</h2>
            <p className='description'> {description} </p>
            {children && children}
          </div>
        </div>
    )
}